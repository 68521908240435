.tippy-box[data-theme~='#C82333'] {
  background: #c82333;
  color: white;
}

.tippy-box[data-theme~='#28883E'] {
  background: #28883e;
  color: white;
}

.tippy-box[data-theme~='#C82333'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: #c82333;
}

.tippy-box[data-theme~='#C82333'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: #c82333;
}

.tippy-box[data-theme~='#C82333'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: #c82333;
}

.tippy-box[data-theme~='#C82333'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: #c82333;
}

.tippy-box[data-theme~='#28883E'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: #28883e;
}

.tippy-box[data-theme~='#28883E'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: #28883e;
}

.tippy-box[data-theme~='#28883E'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: #28883e;
}

.tippy-box[data-theme~='#28883E'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: #28883e;
}
body {
  background-color: #eaeef3 !important;
  word-break: break-word !important;
}


.menu-header {
  color: #FFF;
}

.menu {
  background-color: #343a40 !important;
}

.solucao_aceita {
  background-color: rgb(193, 253, 193);
}

.card-editor {
  min-height: 300px;
}

.swal-modal .swal-text {
  text-align: left;
}

.erpcard {
  border-radius: .5rem;
}

.dropdown-toggle {
  color: black;
}

.icon-mouse-none {
  pointer-events: none;
}

.input-nota {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

a {
  text-decoration: none !important;
}

.bg-header {
  background-color: #343a40;
}

.titulo-header-card {
  color: #ffffff;
}

.notification {
  /* float: right; */
  margin-left: -10px;
}

.header-logo {
  flex-direction: row !important;
}

.sidebar {
  background-color: #343a40;
  min-width: 250px;
  max-width: 250px;
  min-height: calc(100vh - 56px);
  transition: all 0.3s;
}

.pagination>li {
  border-radius: 50% !important;
  margin: 0 5px;
}

.sidebar ul li a {
  display: block;
  padding: 0.75rem 1rem;
  color: #c7cace;
  text-decoration: none;
}

.sidebar ul li a:hover,
.sidebar ul .active a {
  color: #ecedee;
  background-color: #354358;
}

.sidebar ul ul a {
  padding-left: 2.5rem;
  background-color: #1e2835;
}

/*Seletor utilizado para ativar o botão ocultar menu em PC*/
/* .sidebar.toggled {
  margin-left: -250px;
} */

@media (max-width: 768px) {
  /* .sidebar {
    margin-left: -250px;
  } */

  .sidebar.toggled {
    margin-left: 0px;
  }
}

.sidebar [data-toggle="collapse"] {
  position: relative;
}

.sidebar [data-toggle="collapse"]:before {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 1rem;
}

.sidebar [aria-expanded="true"] {
  background-color: #343a40 !important;
}

.sidebar [aria-expanded="true"]:before {
  content: "\f0d8";
}

.content {
  width: 100%;
  margin-left: -196px;
}

.menu-hover {
  z-index: 100;
  position: relative;
}

.menu-texto {
  font-size: 13px;
  margin-left: 10px;
}

.content-sidebar-open {
  width: 100%;
  margin-left: 0px;
}

.icon-burguer {
  font-size: 24px;
}

@media (min-height: 780) {
  .content-sidebar-open {
    width: calc(100% - 250px);
  }
}

.titulo {
  font-size: 1.8rem !important;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.list-group-item {
  border-radius: 7px !important;
}

.fundo-conteudo-inicio {
  background-color: #343a40;
  height: 200px;
  padding: 0px;
}

.bloco-card {
  margin: 20px;
  top: -40px;
}

.bloco-topo {
  margin-top: -120px;
}

.nomeperfil {
  margin-top: 35px;
}

.img-perfil {
  position: relative;
  display: inline-block;
}

.img-perfil:hover .edit {
  display: block;
}

.edit {
  /* padding-top: 7px;
    padding-right: 7px; */
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: #353a40;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-shadow: 0 0 20px 0 rgba(248, 249, 250, .05);
  box-shadow: 0 0 20px 0 rgba(248, 249, 250, .05);
}

.dropdown,
.cursor-pointer {
  cursor: pointer;
}

/*Layout do dashboard*/
.subtitle {
  transition: 1s ease-in-out;
  color: black;
  font-weight: bolder;
  font-size: 16px;
  padding: 20px 0px;
  /* padding: 10% 0px; */
  /* height: 80px; */
}

.titulo-menu-perfil {
  color: black;
  font-weight: bolder;
  padding: 10% 0px;
}

.card-menu-perfil {
  min-width: 120px;
  margin: 5px 0px;
}

.card-dash {
  background-color: #fbfbfb !important;
  border-radius: 10px !important;
}

.card-dash:hover {
  box-shadow: 2px 2px 11px 0px #343b40;
  background-color: #fbfbfb !important;
  border-radius: 10px !important;
}


.img-text-wrapper {
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 80%;
  align-items: center;
  text-align: center;
  /* padding-left: 100px;
    padding-right: 100px; */
  border-radius: 8px;
}


.logo-wrapper img {
  width: 50%;
  margin-bottom: 20px;
}

/* Startups */
.card-startups {
  margin-top: 20px;
}

.directory-card {
  border-radius: 10px !important;
  /* margin: 5% 0px; */
  margin: 20px 0px;
}

.directory-card {
  border-radius: 10px !important;
  /* margin: 5% 0px; */
  margin: 20px 0px;
}

.directory-card:hover {
  box-shadow: 2px 2px 11px 0px #343b40;
}

.etapa-card {
  border-radius: 10px !important;
  margin: 5% 0px;
}

.entrega-card {
  border-radius: 10px !important;
  margin: 10% 0px;
  padding: 5% 0px;
}

.entrega-card:hover {
  box-shadow: 2px 2px 11px 0px #343b40;
}

.directory-bg {
  /* background-image: url(/imagens/fundo_startup.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 130px;
  position: relative;
  padding-top: 100px;
  border-radius: 10px 10px 0px 0px !important;
}

.directory-content {
  padding: 70px 0px 30px 0px;
}

.card-metodologia {
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 10px;
  padding: 20px;
}

.card-imagem-processo {
  border-radius: 10px !important;
  width: auto;
  height: auto;
  /* width: 100%;
    height: 180px; */
}

.titulo-startup {
  transition: 1s ease-in-out;
  color: black;
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 0.3rem;
}

.titulo-generico {
  transition: 1s ease-in-out;
  color: black;
  font-weight: bolder;
  cursor: pointer;
}

.titulo-generico>p {
  font-size: 0.7rem;
  font-weight: 100;
}

.titulo-etapa {
  padding: 10% 0px;
  height: 70px;
}

.texto-cidade {
  font-size: 12px;
  color: black;
}

/*Loadder página acompanhamento*/
.loadder-container {
  padding: 0;
  width: 98%;
  border: 1px solid #28A745;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 15px;
  background-color: white;
  height: 22px;
  position: relative;
}

.loadder-titles {
  position: absolute;
  width: 100%;
  z-index: 999;
  padding: 0 5px 0 5px;
  display: flex;
  justify-content: space-between;
}

.loadder-titles p {
  margin-top: -3px;
}

.loadder-solid {
  position: absolute;
  border-radius: 15px;
  background-color: #28A745;
  height: 100%;
  transition: all 0.6s;
}

.section-title {
  text-align: center;
  font-size: 1.1rem;
  padding: 30px 0 30px 0;
}

.section-title-etapa {
  text-align: center;
  font-size: 1.5rem;
  padding: 30px 0 0 0;
}

details {
  margin-bottom: 20px;
}

details summary {
  list-style: none;
}

.collapse:not(.show) {
  display: block;
}

.collapse-title {
  border: 3px solid transparent;
  border-radius: 10px;
  padding: 20px;
  background-color: #ECECEC;
  transition: all 0.4s;
}

.borderless {
  border-left: none;
}

.collapse-title::after {
  content: '▼';
}

.collapse[open] .collapse-title::after {
  content: '▲';
}

.collapse-title:hover {
  transform: translateY(-5px);
}

.section-tabs {
  transition: all 0.6s;
  border: 3px solid transparent;
}

.section-tabs:hover {
  transform: translateY(-3px);
}

.collapse-title p:first-child {
  font-weight: bold;
}

.collapse-title p:last-child {
  margin-top: -10px;
}

.collapse-body {
  border: 2px solid #f1f1f1;
  border-radius: 10px;
  padding: 20px;
}

.collapse-body_title:not(:last-child) {
  border-bottom: 2px solid #f1f1f1;
}

.collapse-body_title:not(:first-child) {
  margin-top: 20px;
}

.collapse-body_title button {
  margin: 20px 0 20px 0;
}

.collapse-body_title p:first-child {
  font-weight: bold;
}

.collapse-body_title p:last-child {
  margin-top: -10px;
}

.collapse-body_header {
  display: block;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.collapse-body_header_chart {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}


.show-evidences {
  border: none;
  background-color: transparent;
  position: relative;
  z-index: 999;
}

.show-evidences:hover {
  text-decoration: underline;
}

.section-labels_container {
  display: flex;
  align-items: center;
  flex-direction: row;

  margin-top: 40px;
}

.section-labels_content {
  display: flex;
  margin-right: 10px;
}

.section-labels_content p {
  font-size: 0.7rem;
  margin-left: 5px;
}

.section-labels_circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.module-description {
  margin-top: 20px;
}

.module-lastedit {
  font-size: 12px;
  font-style: italic;
  text-align: center;
}

.titulo-principal-jornada {
  margin: 5px 0 30px 0;
}

.titulo-principal-jornada {
  margin: 5px 0 30px 0;
}

.collapse-section_title {
  text-align: left;
  font-size: 1.3rem;
  width: 100%;
  border-top: 2px solid #f1f1f1;
  padding-top: 30px;
}

.collapse-section_subtitle {
  font-size: 0.9rem;
  margin-top: -10px;
}

/* skeleton */
.card-skeleton {
  margin-top: 100px;
}

.skeleton-directory-card {
  border-radius: 10px !important;
  margin: 5% 0px;
}

.directory-skeleton-bg {
  background-color: lightgray;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 130px;
  position: relative;
  padding-top: 100px;
  border-radius: 10px 10px 0px 0px !important;
}

/* Aulas */
.card-imagem-trilha {
  border-radius: 10px 10px 0px 0px !important;
  min-width: 100%;
}

.card-eventos {
  object-fit: cover;
  height: 200px;
}

.class-card {
  box-shadow: 1px 1px 8px 0px #acb1b5a6;
}

.class-title {
  transition: 1s ease-in-out;
  color: black;
  font-weight: bolder;
  text-align: top left;
  height: 20px;
}

.class-author {
  transition: 1s ease-in-out;
  color: black;
  text-align: left;
  height: 40px;
  font-size: 14px;
  padding: 7px 0 0 5px;
}

.classes {
  transition: 1s ease-in-out;
  color: black;
  text-align: left;
  font-weight: bolder;
  text-align: top left;
  height: 20px;
}

.handle-height {
  max-height: 400px;
}

.btn-outline-primary {
  color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

/* Timeline */
.cd-container {
  width: 90%;
  margin: 0 auto;
}

.cd-container::after {
  content: '';
  display: table;
  clear: both;
}

#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
}

#cd-timeline::before {
  border-left: 3px solid #eeeeee;
  content: '';
  height: 100%;
  left: 18px;
  position: absolute;
  top: 0;
  width: 3px;
}

/* @media only screen and (min-width: 1170px) {
    #cd-timeline {
      margin-bottom: 3em;
      margin-top: 3em;
    }
    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  } */

.cd-timeline-block {
  margin: 2em 0;
  position: relative;
}

.cd-timeline-block:after {
  clear: both;
  content: "";
  display: table;
}

.cd-timeline-block:first-child {
  margin-top: 0;
}

.cd-timeline-block:last-child {
  margin-bottom: 0;
}

/* @media only screen and (min-width: 1170px) {
    .cd-timeline-block {
      margin: 4em 0;
    }
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  } */

.cd-timeline-img {
  position: absolute;
  top: 18px;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  color: #ffffff;
  background-color: #67a8e4;
  border: 5px solid #ffffff;
}

.cd-timeline-img i {
  margin-left: 1px;
}

/* @media only screen and (min-width: 1170px) {
    .cd-timeline-img {
      width: 40px;
      height: 40px;
      line-height: 30px;
      left: 50%;
      margin-left: -20px;
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
    }
    .cssanimations .cd-timeline-img.is-hidden {
      visibility: hidden;
    }
    .cssanimations .cd-timeline-img.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-1 0.6s;
      animation: cd-bounce-1 0.6s;
    }
  } */

.cd-timeline-content {
  border-radius: 5px;
  border: 2px solid #eeeeee;
  margin-left: 60px;
  padding: 1em;
  position: relative;
}

.cd-timeline-content:after {
  clear: both;
  content: "";
  display: table;
}

.cd-timeline-content h2 {
  margin-top: 0;
}

.cd-timeline-content .cd-read-more {
  background: #67a8e4;
  border-radius: 0.25em;
  color: white;
  display: inline-block;
  float: right;
  font-size: 14px;
  padding: .8em 1em;
}

.cd-timeline-content .cd-date {
  display: inline-block;
  font-size: 14px;
}

.cd-timeline-content h3 {
  font-size: 18px;
  margin: 0 0 15px 0;
}

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}

.cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}

.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 12px solid transparent;
  border-right: 12px solid #eeeeee;
}

.cd-timeline-content .cd-read-more {
  float: left;
}

/* Noticias, conteudos e insights */
.img-news {
  max-width: 100%;
  height: auto;
}

.zero-padding {
  padding-left: 0%;
  padding-right: 0%;
}

.padding-content-class {
  padding: 1rem 2rem;
}

.materia-aula {
  font-size: 12px;
  margin-bottom: 0rem;
}

.img-news-list {
  max-width: 100%;
  height: auto;
}

.card-imagem-news {
  border-radius: .25rem .25rem 0px 0px !important;
}

.coluna-tabela {
  min-width: 200px;
}

.news-card {
  box-shadow: 1px 1px 8px 0px #acb1b5a6;
}

.card-body-news {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
}

.nav-news {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  margin-bottom: 0;
  list-style: none;
}

.text-news {
  display: flex;
  color: #000000 !important;
  font-weight: bolder;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
}


/* Observacoes */

.observacoes {
  background-color: #f3f3f3;
  margin: 0px -16px -16px -16px !important;
  padding: 0px !important;
  box-shadow: inset 0px 2px 2px 0px #acb1b5a6;
}

/*   
  @media only screen and (min-width: 1170px) {
    .cd-timeline-content {
      margin-left: 0;
      padding: 1.6em;
      width: 45%;
    }
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #eeeeee;
    }
    .cd-timeline-content .cd-read-more {
      float: left;
    }
    .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 16px;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #eeeeee;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 122%;
      text-align: right;
    }
    .cssanimations .cd-timeline-content.is-hidden {
      visibility: hidden;
    }
    .cssanimations .cd-timeline-content.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-2 0.6s;
      animation: cd-bounce-2 0.6s;
    }
  } */

/* @media only screen and (min-width: 1170px) {
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
      -webkit-animation: cd-bounce-2-inverse 0.6s;
      animation: cd-bounce-2-inverse 0.6s;
    }
  }
   */

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}

.avatar-lg {
  max-width: 100px !important;
}

.page-item.active .page-link {
  border-radius: 6px;
  border-color: #343a40;
}

.linha {
  display: flex;
}


/* Tabs */
.nav-tabs .nav-link,
.nav-pills .nav-link {
  font-family: "Roboto", sans-serif;
  color: #1d1e3a;
  font-size: 14px;
}

.nav-tabs-custom {
  border-bottom: 2px solid #DDD;
}

.sem-borda {
  border-bottom: 0px solid #DDD !important;
}

.nav-tabs-custom .nav-item {
  position: relative;
}

.nav-tabs-custom .nav-item .nav-link {
  border: none !important;
}

.nav-tabs-custom>li>a {
  color: #1d1e3a;
}

.nav-tabs-custom>li>a::after {
  content: "";
  background: #67a8e4;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  -webkit-transition: all 250ms ease 0s;
  transition: all 250ms ease 0s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.nav-tabs-custom>li>a.active::after,
.nav-tabs-custom>li:hover>a::after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nav-tabs-custom>li>a.active {
  color: #67a8e4 !important;
}


/* Ecossistema */
.nav-tabs-custom>li>a.active {
  color: #343a40 !important;
  border-bottom: 1px solid #343a40 !important;
  cursor: pointer;
}

.nav-tabs-custom>li>a:hover {
  color: #343a40 !important;
  border-color: #343a40 !important;
  cursor: pointer;
  border-bottom: 1px solid #343a40 !important;
}

.nav-tabs .nav-link,
.nav-pills .nav-link {
  font-size: 18px !important;
}

/* Radar */
.media-radar {
  padding: 10px 30px !important;
  margin: 5px 0px;
}

.form-check {
  padding-left: 40px !important;
}

.display-none {
  display: none !important;
}


.form-control-file,
.form-control-range {
  border: 0px !important;
}


@media (max-height: 850px) {
  .video {
    height: 400px !important;
  }

  .mensagem-dados {
    margin: 30px
  }
}

@media (min-height: 1020) {
  .video {
    height: 600px !important;
  }
}

@media (min-height: 1021) {
  .video {
    height: 800px !important;
  }
}

.row-nome {
  width: 20%;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


/* Onde estou */

.guia {
  background-color: #f3f3f3;
  margin: 0px -16px -16px -16px !important;
  padding: 0px !important;
  box-shadow: inset 0px 2px 2px 0px #acb1b5a6;
  border-radius: 10px 10px 10px 10px !important;
  max-width: 400px;

}

.text-perfil {
  color: black;
  margin-right: 3px;
}


.menu-cabecalho-active {
  background-color: #343a4030;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
}

.menu-cabecalho:hover {
  background-color: #343a4030;
  padding: 10px 15px;
  border-radius: 5px;
}

.menu-cabecalho {
  padding: 10px 15px;
}

.mensagem-dados {
  margin: 10% 30px
}


.page-link {
  color: #343a40;
  cursor: pointer;
}


.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 1em;
  font-weight: 20;
  color: #555;
  user-select: none;
}

.row-progress {
  width: 30%;
}

.btn-classes {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: left;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.cursor-clique {
  cursor: pointer;
}

.opacity-50 {
  opacity: 0.5;
}

.icon-arrow {
  margin-top: 0.6px;
}

.nav-conteudo .nav-link.active,
.nav-conteudo .show>.nav-link {
  color: #fff;
  background-color: #343a40;
  border-radius: .25rem;

}

.botao-estilo {
  margin: 10px 0px;
}

.botao-menu-componente {
  border: 1px solid #4b4b4b;
  border-radius: 5px;
  margin: 0px 5px;
}

.menu-centralizado {
  text-align: center !important;
}

/* MOBILE */

@media (max-width: 950px) {

  .centralizado {
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .texto-centralizado {
    text-align: center !important;
  }

  .bloco-topo {
    margin-top: -80px;
  }

  .botao-pesquisa-responsivo {
    width: 100%;
    margin: 5px 0px;
  }

  .card-imagem-news {
    min-width: 100% !important;
  }

}

@media (max-width: 500px) {

  .botao-responsivo {
    width: 100%;
    margin: 5px 0px;
  }


}


.nome-aula {
  padding-left: 10px !important;
}


.align-left {
  text-align: left !important;
}

.text-danger {
  color: #dc3545;
}

.bg-secondary {
  background-color: #212529;
  border-radius: 100px !important;
}

.text-white {
  color: #ffff;
}

.margin-zero {
  margin-bottom: 0px !important;
}

.text-20 {
  font-size: 20px;
}

.App {
  font-family: sans-serif;
  height: 500px;
  width: 100%;
}

.geral {
  font-size: 100px;
  padding-top: 80px;
}


.rel-ativado {
  display: block;
}

.rel-desativado {
  display: none;
}

.text-link {
  color: cyan;
}

.bg-green {
  background-color: lightgreen;

}

.bg-secondary-borderless {
  background-color: lightgray;
}

.baloon-left {
  justify-content: left;
}

.baloon-right {
  float: right;
}

div.chats {
  height: 500px;
  overflow: auto;
}

.datahora-msg {
  font-size: 12px;
}

.datahora-etapa {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  margin: -10px 0 30px 0;
}

.datahora-chat {
  font-size: 14px;
}

div.conversa {
  background-color: rgb(187, 190, 194);
  height: 500px;
  border-radius: 5px;
  overflow: auto;
}

.padding-0 {
  padding-top: 0px !important;
}

.text-12 {
  font-size: 12px;
}

.bg-hom {
  background-color: #801212 !important;
}

.alerta-hom {
  margin: 8px 30px
}

@keyframes animateRating {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.rate-active {
  display: flex;
  align-items: center;
  padding-top: 5px;
  animation: animateRating linear 0.3s;
}

.rate-active button {
  margin-top: -6px;
}

.directory-card {
  display: flex;
  flex-direction: column;
  color: #000000;
}

.listagem-trilhas {
  width: 100%;
  height: 250px;
  /* max-width: 486px;
  max-height: 323px; */
  object-fit: cover;
}

.directory-card h5 {
  font-size: 1.3rem;
}

.card-title-access {
  display: flex;
  justify-content: space-between;
}

.card-rate {
  text-align: right;
}

.card-date {
  text-align: left;
}

@media (max-width: 950px) {
  .card-rate {
    text-align: center;
  }

  .card-date {
    text-align: center;
  }
}

.card-title-access:last-child {
  margin-top: 40px;
}

.card-author-name {
  font-size: 0.8rem;
}

.card-author-name>p span {
  font-weight: bold;
}

.text-muted {
  margin-bottom: 0;
}

.text-muted-trilhas {
  margin-bottom: 0;
  height: 50px;
}

.ql-container {
  min-height: 300px;
}

.ql-editor {
  min-height: 300px;
}
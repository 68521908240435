html,
body {
  height: 100%;
}
.login{
  margin: 10%;
  width: 100%;
  padding: 20px;
  
}
.form-signin {
  margin-top: 40%;
  width: 85%;
  
}
/* 
@media all and (min-width: 480px){
    .login{
        padding: 10px 0;
        width: 350px;
    }
}  */

.tela-login{
  padding: 0px !important;
  background-color: #ffffff;
  width: 100%;
}
.sem-padding{
  padding: 0px !important;
}
.sombra{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.8)!important;
}

.fundo-img-login{
  padding: 0px !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.8)!important;
  /* position: fixed;  */
	min-height: 100%;
}